export class UrlQueryKey {}

export class LocalStorageKey {
	static USER_INFO = 'userInfo'
	static WORK_NAME = 'workName'
	static PRINT_PARAM = 'printParam'
	static UPLOAD_TASK_TYPE = 'uploadTaskType'

	/********** 作业批改相关 **********/
	static HOMEWORK_REVIEW = 'homeworkReview'
	static HOMEWORK_REVIEW_STUDENT = 'homeworkReviewStudent'

	static HOMEWORK_ANSWER_INFO = 'homeworkAnswerInfo'
	static HOMEWORK_STUDENT_REVIEW_INFO = 'homeworkStudentReviewInfo'
	static HOMEWORK_QUESTION_REVIEW_INFO = 'homeworkQuestionReviewInfo'

	static HOMEWORK_DETAIL_DATA_TYPE = 'homeworkDetailDataType'
	/********** 作业批改相关 **********/

	/** 题库相关 start */
	static QUESTION_LIB_QUESY = 'questionLibQuery'
	/** 题库相关 end */
}

export class TimeKey {
	static SMS = 60
}
export class SessionStorageKey {
	static USER_INFO = LocalStorageKey.USER_INFO
}

export class EventKey {}

export class ErrorServerKey {}
