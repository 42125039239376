import http from '@/utils/http'

export interface OrganizationDTO {
	organizationId: number
	organizationName: string
}

export function apiQueryOrganization() {
	return http<Array<OrganizationDTO>>('GET', '/api/organization/user/bindingOrganizationList/query', {}, { domainName: 'user' })
}

// 查询机构下所有的学校
export interface SchoolDTO {
	id: number
	schoolName: string
}

export function apiQuerySchoolByOrganizationId(organizationId: number) {
	return http<Array<SchoolDTO>>('GET', '/api/organization/bindingSchoolList/query', { organizationId }, { domainName: 'user' })
}
