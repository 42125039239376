import G, { DOMAIN_MAP } from '@/config/config'
import { EnvName } from '@/config/NodeEnv'
import RequestPromise from '@/extend'
import axios, { Canceler, CancelToken, Method } from 'axios'
import { ElNotification } from 'element-plus'
import { getUserInfo } from './special/getUserInfo'
import { loginOut } from './utils'

type RequestResult = {
	code: number
	msg: string | undefined
	data: unknown
}

type RequestConfig = {
	domainName?: keyof typeof DOMAIN_MAP
	cancelPreviousRequest?: RequestPromise<unknown> | null
	mock?: number | string
	localMock?: boolean
	tokenExpireUncheck?: boolean
	showMsg?: boolean
	platform?: number | string
	timeout?: number
	headers?: Record<string, unknown>
}

type AxiosRequestConfig = {
	timeout: number
	headers: {
		accessToken: string
	}
	cancelToken: CancelToken
	method: Method
	url: string
	params?: unknown
	data?: unknown
}

const DEFAULT_REQUEST_CONFIG: RequestConfig = {
	showMsg: true,
	platform: import.meta.env.VITE_PLATFORM,
}

function getRequestUrl(url: string, config: RequestConfig): string {
	let requestUrl = ''
	//是否是mock
	if (G.isMock && config.mock !== 0 && config.mock && import.meta.env.MODE !== EnvName.PRODUCTION) {
		requestUrl = import.meta.env.VITE_MOCK_URL + config.mock
	}
	//是否是本地mock
	else if (G.isMock && config.localMock && import.meta.env.MODE !== EnvName.PRODUCTION) {
		requestUrl = 'http://localhost:33333'
	} else {
		requestUrl = DOMAIN_MAP[config.domainName || 1] as string
	}

	//url是否已http开头
	if (url.startsWith('http')) {
		requestUrl = url
	}
	//正常拼接
	else {
		requestUrl += url
	}

	return requestUrl
}
const http = function <T>(method: Method, url: string, params: unknown, requestConfig?: RequestConfig): RequestPromise<T> {
	const config: RequestConfig = JSON.parse(JSON.stringify(DEFAULT_REQUEST_CONFIG))
	Object.assign(config, requestConfig)
	if (config.cancelPreviousRequest) {
		config.cancelPreviousRequest.__abort('Canceled Request:' + url)
	}
	const requestUrl = getRequestUrl(url, config)
	const headers = {
		accessToken: getUserInfo()?.accessToken || '',
		device: 'web',
	}
	if (config.headers) {
		Object.assign(headers, config.headers)
	}
	let abort: Canceler = () => {
		console.log('Not have axios Canceler.')
	}
	const axiosRequestConfig: AxiosRequestConfig = {
		timeout: config.timeout || 15000,
		cancelToken: new axios.CancelToken(function executor(cancel) {
			abort = cancel
		}),
		headers,
		method: method,
		url: requestUrl,
	}
	const upperMethod = String.prototype.toUpperCase.call(method)
	if (upperMethod === 'GET' || upperMethod === 'DELETE') {
		axiosRequestConfig.params = params
	} else {
		axiosRequestConfig.data = params
	}

	const request = new RequestPromise<T>((resolve, reject) => {
		axios
			.request<RequestResult>(axiosRequestConfig)
			.then(resp => {
				if (G.isMock && config.mock && import.meta.env.MODE !== EnvName.PRODUCTION) {
					resp.data.code = 200
				}
				if (resp.data.code === 30000 && !requestConfig?.tokenExpireUncheck) {
					loginOut()
					return
				}

				if (resp.data.code === 200) {
					resolve(resp.data.data as T)
				} else {
					if (config.showMsg) {
						ElNotification({
							title: '提示',
							type: 'error',
							message: resp.data.msg,
							zIndex: 99999,
						})
					}
					reject(resp.data)
				}
			})
			.catch(err => {
				if (config.showMsg) {
					ElNotification({
						type: 'error',
						title: '请求出错',
						message: err.message,
						zIndex: 99999,
					})
				}
				reject(err)
			})
	}, abort)
	return request
}
export default http
