import { LocalStorageKey } from '@/config/enum'
import { UserInfo } from '@/vo/UserInfo'

export const getUserInfo = function (): UserInfo | undefined {
	let userInfo: UserInfo | undefined
	try {
		userInfo = JSON.parse(localStorage.getItem(LocalStorageKey.USER_INFO) || '')
	} catch (error) {
		userInfo = undefined
	}
	return userInfo
}
