<template>
	<div class="nav-bar">
		<div class="operate">
			<!-- 汉堡按钮 -->
			<Hamburger />
			<!-- todo 面包屑 -->
			<BreadCrumb />
		</div>
		<div class="user-info">
			<img class="avatar" src="./img/avatar.png" alt="" />
			<span class="name">{{ userInfo?.name }}老师</span>
			<div class="icon-container" @click="loginOut">
				<img class="icon" src="./img/logout.png" alt="" />
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { useStore } from 'vuex'
import { VuexStateKey } from '@/store/vuexEnum'
import { loginOut } from '@/utils/utils'
import type { UserInfo } from '@/vo/UserInfo'
import Hamburger from '../hamburger/Hamburger.vue'
import BreadCrumb from '../breadCrumb/BreadCrumb.vue'

const store = useStore()
const userInfo: UserInfo = $computed(() => store.state[VuexStateKey.userInfo])
</script>

<style scoped lang="scss">
.nav-bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	.operate {
		display: flex;
		align-items: center;
	}
	.user-info {
		display: flex;
		align-items: center;
		gap: 8px;
		.avatar {
			width: 24px;
			height: 24px;
		}
		.icon-container {
			width: 38px;
			height: 60px;
			line-height: 60px;
			padding-top: 20px;
			cursor: pointer;
			margin-left: 12px;
		}
		.name {
			font-size: 13px;
			font-family: $theme-text-family-1;
			font-weight: 400;
			color: $base-color-gray;
		}
		.icon {
			position: relative;
			top: 1px;
			width: 20px;
			height: 20px;
		}
	}
}
</style>
