import G from '@/config/config'
import { LocalStorageKey } from '@/config/enum'
import http from '@/utils/http'
import { getUserInfo } from '@/utils/special/getUserInfo'
import { UserInfo } from '@/vo/UserInfo'
import { GradeInfoData } from '@/api/noAuthDashboard/school'
import { RouteRecordRaw } from 'vue-router'
import { createStore } from 'vuex'
import { VuexActionKey, VuexMutationKey, VuexStateKey } from './vuexEnum'
type G = typeof G

export interface ENUM_S {
	[prop: string]: string | number
}

// 题库相关枚举
export interface QUESTION_LIB_ENUM {
	[prop: string]: QuestionLibEnumItem[]
}

// interface SUBMIT_STATUS

interface ResQuerySubjectList {
	subjectGroupList: Array<ResSubjectGroupList>
	subjectList: Array<ResSubjectList>
}

interface ResSubjectGroupList {
	type: number
	name: string
}
export interface ResSubjectList {
	index: number
	type: number
	name: string
	detailName: string
}

export interface TeacherPermissionInfo {
	permissionName: string
	permission: boolean
}

export interface OrganizationPermissionInfo {
	permission: boolean
	openServiceType: number
}

// 题库相关枚举
export interface QuestionLibEnumItem {
	index: number
	desc: string
	parentIndexList: number[]
}
const store = createStore({
	state: {
		[VuexStateKey.G]: G,
		[VuexStateKey.userInfo]: getUserInfo(),
		ENUM_SUBJECT: {} as ENUM_S,
		ENUM_SUBJECT_GROUP: {} as ENUM_S,
		ENUM_SUBJECT_INDEX_TYPE: {} as ENUM_S,
		[VuexStateKey.workName]: localStorage.getItem(LocalStorageKey.WORK_NAME),
		[VuexStateKey.TEACHER_PERMISSION]: {} as TeacherPermissionInfo,
		[VuexStateKey.ORGANIZATION_PERMISSION]: {} as OrganizationPermissionInfo,
		[VuexStateKey.HAMBURGER_OPENED]: true,
		[VuexStateKey.EXTEND_BREAD]: '',
		[VuexStateKey.FOOTER_SHOW]: false,
		[VuexStateKey.QUESTION_LIB_ENUM]: {} as QUESTION_LIB_ENUM,
		[VuexStateKey.ORGANIZATION_ID]: null,
		[VuexStateKey.DEGREE_CLASS_MAP]: {} as ENUM_S,
	},
	mutations: {
		[VuexMutationKey.updateUserInfo](state, userInfo: UserInfo) {
			console.log('updateUserInfo', userInfo)
			state[VuexStateKey.userInfo] = userInfo === undefined ? undefined : Object.assign({}, userInfo)
		},
		[VuexMutationKey.setEnumSubject](state, res: ResQuerySubjectList) {
			res.subjectGroupList.forEach(item => {
				state.ENUM_SUBJECT_GROUP[item.type] = item.name
				state.ENUM_SUBJECT_GROUP[item.name] = item.type
			})
			res.subjectList.forEach(item => {
				state.ENUM_SUBJECT[item.index] = item.detailName
				state.ENUM_SUBJECT[item.detailName] = item.index
				state.ENUM_SUBJECT_INDEX_TYPE[item.index] = item.type
			})
		},
		[VuexMutationKey.setWorkName](state, workName: string) {
			state[VuexStateKey.workName] = workName
		},
		[VuexMutationKey.setTeacherPermission](state, permissionInfo: TeacherPermissionInfo) {
			state[VuexStateKey.TEACHER_PERMISSION] = permissionInfo
		},
		[VuexMutationKey.setOrganizationPermission](state, permissionInfo: OrganizationPermissionInfo) {
			state[VuexStateKey.ORGANIZATION_PERMISSION] = permissionInfo
		},
		[VuexMutationKey.toggleHamburger](state, type: 'open' | 'close' | undefined) {
			if (type) {
				state[VuexStateKey.HAMBURGER_OPENED] = type === 'open'
			} else {
				state[VuexStateKey.HAMBURGER_OPENED] = !state[VuexStateKey.HAMBURGER_OPENED]
			}
		},
		[VuexMutationKey.setExtendBread](state, breadName: string) {
			state[VuexStateKey.EXTEND_BREAD] = breadName
		},
		[VuexMutationKey.changeFooterShow](state, isShow: boolean) {
			state[VuexStateKey.FOOTER_SHOW] = isShow
		},
		[VuexMutationKey.setQuestionLibEnum](state, questionLibEnum: QUESTION_LIB_ENUM) {
			state[VuexStateKey.QUESTION_LIB_ENUM] = questionLibEnum
		},
		[VuexStateKey.ORGANIZATION_ID](state, organizationId: string) {
			state[VuexStateKey.ORGANIZATION_ID] = organizationId
		},
		[VuexMutationKey.setDegreeClassMap](state, gradeInfoList: GradeInfoData[]) {
			if (gradeInfoList && gradeInfoList.length) {
				state[VuexStateKey.DEGREE_CLASS_MAP] = gradeInfoList.reduce((pre: any, cur: GradeInfoData) => {
					const { degreeCode } = cur
					if (pre[degreeCode]) {
						pre[degreeCode].push(cur)
					} else {
						pre[degreeCode] = [cur]
					}
					return pre
				}, {})
			} else {
				state[VuexStateKey.DEGREE_CLASS_MAP] = null
			}
		},
	},
	actions: {
		[VuexActionKey.setOrganizationPermission](context) {
			return http<OrganizationPermissionInfo[]>(
				'GET',
				'/api/organization/user/checkPermission',
				{
					organizationId: context.state[VuexStateKey.ORGANIZATION_ID],
				},
				{
					domainName: 'user',
				}
			).then(res => {
				context.commit(VuexActionKey.setOrganizationPermission, res)
			})
		},
		// 获取题库相关枚举
		[VuexActionKey.setQuestionLibEnum](context) {
			return http<QUESTION_LIB_ENUM>('GET', '/enums/queryEnumList', {}, { domainName: 'question' }).then(res => {
				if (res?.schoolBankTypeEnum) {
					// 处理来源
					const bankAllType = res.schoolBankTypeEnum.find(item => item.desc === '全部')!
					const otherBankTypes = res.schoolBankTypeEnum.filter(item => item.desc !== '全部')
					res.schoolBankTypeEnum = [bankAllType, ...otherBankTypes]
				}
				if (res?.difficultyEnum) {
					// 处理难度
					res.difficultyEnum = [{ index: -1, desc: '全部', parentIndexList: [] }, ...res.difficultyEnum.filter(item => item.desc !== '全部')]
				}

				context.commit(VuexMutationKey.setQuestionLibEnum, res)
			})
		},
		[VuexStateKey.ORGANIZATION_ID](context, organizationId: string) {
			context.commit(VuexStateKey.ORGANIZATION_ID, organizationId)
		},
	},
	modules: {},
})

export default store
