import { setPageTitle } from '@/utils/common/system'
import Main from '@/layout/Layout.vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { isLogin } from '@/utils/utils'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'
import store from '@/store'
import { apiQueryOrganization, apiQuerySchoolByOrganizationId, OrganizationDTO, SchoolDTO } from '@/api/organization'
import { VuexStateKey } from '@/store/vuexEnum'

export const routes: Array<RouteRecordRaw> = [
	{
		path: '/test',
		name: 'test',
		component: () => import('../views/Test.vue'),
	},
	{
		path: '/',
		name: 'home',
		redirect: '/home',
		component: Main,
		meta: {
			title: '数据看板',
		},
		children: [
			{
				path: '/home',
				name: 'home',
				component: () => import('../views/home/Home.vue'),
				meta: {
					icon: '&#xe711;',
					menuShow: true,
					title: '服务使用情况概览',
					openServiceType: 1,
				},
			},
		],
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/Login/Login.vue'),
		meta: {
			isConstRoute: true,
			noLogin: true,
		},
	},
	{
		path: '/dashboard_region',
		name: 'dashboardRegion',
		component: () => import('../views/noAuthDashboard/regionDashboard.vue'),
		meta: {
			menuShow: false,
			noLogin: true,
			title: '区域看板',
		},
	},
	{
		path: '/dashboard_school',
		name: 'dashboardSchool',
		component: () => import('../views/noAuthDashboard/schoolDashboard.vue'),
		meta: {
			menuShow: false,
			noLogin: true,
			title: '学校看板',
		},
	},
	// {
	// 	name: '404',
	// 	path: '/:pathMatch(.*)',
	// 	component: () => import('@/views/404/404.vue'),
	// 	meta: {
	// 		title: '404',
	// 		noLogin: true,
	// 	},
	// },
]

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes,
})
router.afterEach((to, from) => {
	NProgress.done()
	if (to.meta.title && !to.meta.noLogin) {
		setPageTitle(`小可教师版—` + to.meta.title)
	} else {
		setPageTitle(to.meta.title)
	}
	const elMain = document.querySelector('.el-main')
	// 重置滚动条
	if (elMain) {
		elMain.scrollTop = 0
	}
})

export default router

NProgress.configure({ showSpinner: false })

router.beforeEach(async (to, from, next) => {
	NProgress.start()
	const noLogin = to?.meta?.noLogin
	const needLogin = noLogin === void 0 ? true : !noLogin
	if ((!/404/.test(to.path) && to.name === '404') || needLogin) {
		if (isLogin()) {
			try {
				const orgId = localStorage.getItem('organizationId') || ''
				if (orgId) {
					const schList = await apiQuerySchoolByOrganizationId(Number(orgId))
					generateRouter(schList)
					if (to.name !== 'home') {
						next(to)
					} else {
						next()
					}
				} else {
					next({
						name: 'home',
					})
				}
				// await store.dispatch('setOrganizationPermission')
				// next()
			} catch (e) {
				console.log(e)
				next()
			}
		} else {
			console.log('this route need login.')
			next({
				name: 'login',
			})
		}
	} else {
		next()
	}
	// const subjects = Object.keys(store.state[VuexStateKey.ENUM_SUBJECT] || {})
	// if (subjects.length == 0 && to.name !== 'login') {
	// 	store.dispatch('setEnumSubjectAsync')
	// 	store.dispatch('setQuestionLibEnum')
	// }
})
function generateRouter(schList: SchoolDTO[]) {
	router.addRoute({
		path: '/schoolDashboard',
		name: 'schoolDashboard',
		component: Main,
		meta: {
			title: '区域学校',
			menuShow: true,
		},
		children: schList.map(sch => ({
			path: `/schoolDashboard/${sch.id}`,
			name: sch.id + '',
			component: () => import('@/views/schoolDashboard/SchoolDashboard.vue'),
			props: {
				id: sch.id,
			},
			meta: {
				menuShow: true,
				title: sch.schoolName,
				noLogin: true,
			},
		})),
	})
	routes.push({
		name: '404',
		path: '/:pathMatch(.*)',
		component: () => import('@/views/404/404.vue'),
		meta: {
			title: '404',
			noLogin: true,
		},
	})
}
