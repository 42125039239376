import { LocalStorageKey } from '@/config/enum'

export class VuexStateKey {
	static G = 'G'
	static userInfo = LocalStorageKey.USER_INFO
	static ENUM_SUBJECT = 'ENUM_SUBJECT'
	static ENUM_SUBJECT_GROUP = 'ENUM_SUBJECT_GROUP'
	static ENUM_SUBJECT_INDEX_TYPE = 'ENUM_SUBJECT_INDEX_TYPE'
	static workName = 'workName'
	static TEACHER_PERMISSION = 'TEACHER_PERMISSION'
	static ORGANIZATION_PERMISSION = 'ORGANIZATION_PERMISSION'
	static ORGANIZATION_ID = 'ORGANIZATION_ID'
	static SCHOOL_LIST = 'SCHOOL_LIST'
	static HAMBURGER_OPENED = 'HAMBURGER_OPENED'
	/** 扩展的面包屑 */
	static EXTEND_BREAD = 'EXTEND_BREAD'
	/** 是否展示footer */
	static FOOTER_SHOW = 'FOOTER_SHOW'
	static QUESTION_LIB_ENUM = 'QUESTOIN_LIB_ENUM'
	static DEGREE_CLASS_MAP = 'DEGREE_CLASS_MAP'
}
export class VuexMutationKey {
	static updateUserInfo = 'updateUserInfo'
	static setEnumSubject = 'setEnumSubject'
	static setWorkName = 'setWorkName'
	static setTeacherPermission = 'setTeacherPermission'
	static setOrganizationPermission = 'setOrganizationPermission'
	static toggleHamburger = 'toggleHamburger'
	static setExtendBread = 'setExtendBread'
	static changeFooterShow = 'changeFooterShow'
	static setQuestionLibEnum = 'setQuestionLibEnum'
	static setDegreeClassMap = 'setDegreeClassMap'
}

export class VuexActionKey {
	static setEnumSubjectAsync = 'setEnumSubjectAsync'
	static setTeacherPermission = 'setTeacherPermission'
	static setOrganizationPermission = 'setOrganizationPermission'
	static setQuestionLibEnum = 'setQuestionLibEnum'
}
