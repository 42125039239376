import { RouteRecordRaw } from 'vue-router'
import store, { TeacherPermissionInfo } from '@/store'
import { VuexStateKey } from '@/store/vuexEnum'
/**
 * 由于router.getRoutes获取到的路由信息包含每个路由信息以及含有children信息的整体路由信息
 * 所以要获取出route下的所有children，方便将脱离层级的路由剔除
 * @param routes 通过router.getRoutes获取到的路由信息
 */
const getChildrenRoutes = (routes: RouteRecordRaw[]) => {
	const childrenRoutes: RouteRecordRaw[] = []
	const teacherPermission = store?.state[VuexStateKey.TEACHER_PERMISSION] as unknown as TeacherPermissionInfo[]
	let hasReviewPermission = false
	if (JSON.stringify(teacherPermission) !== '{}') {
		hasReviewPermission = teacherPermission?.filter(item => item.permissionName === 'customizedExerciseBook' && item.permission).length > 0
	}

	routes.forEach(route => {
		if (route.children && route.children.length > 0) {
			childrenRoutes.push(...route.children)
		}
	})
	// 如果有review权限则展示设计作业入口
	if (hasReviewPermission) {
		for (let i = 0; i < childrenRoutes.length; i++) {
			if (childrenRoutes[i].name === 'homeworkDesign' && childrenRoutes[i].meta) {
				childrenRoutes[i].meta!.menuShow = true
			}
		}
	}
	return childrenRoutes
}

/**
 * 通过得到的childrenRoutes，将脱离层级的route过滤出去，只保留有层级的路由信息
 * 并且获取到所有含有子路由信息的路由
 * @param routes
 */
export const filterRoutes = (routes: RouteRecordRaw[]) => {
	const childrenRoutes = getChildrenRoutes(routes)
	const unRemovedRoutes = routes.filter(route => {
		return (
			route.children?.length &&
			route.children.length > 0 &&
			!childrenRoutes.find(childrenRoute => {
				return childrenRoute.path === route.path
			})
		)
	})
	// 过滤掉不需要展示的子路由信息
	unRemovedRoutes.forEach(route => {
		route.children = route.children!.filter(route => route.meta?.menuShow)
	})
	return unRemovedRoutes
}
