<template>
	<div class="app-aside">
		<div class="logo" :class="{ only: !isShow }">
			<img class="img" src="./img/logo.png" />
			<img v-if="showLogo" class="title" src="./img/logo-text.png" />
		</div>
		<div class="menu">
			<AsideMenu />
		</div>
	</div>
</template>
<script setup lang="ts">
import { watch } from 'vue'
import { useStore } from 'vuex'
import { VuexStateKey } from '@/store/vuexEnum'
import AsideMenu from './components/AsideMenu.vue'

let showLogo = $ref(true)
const store = useStore()
const isShow = $computed(() => store.state[VuexStateKey.HAMBURGER_OPENED])

watch(
	() => isShow,
	val => {
		if (val) {
			showLogo = true
		} else {
			setTimeout(() => {
				showLogo = false
			}, 200)
		}
	}
)
</script>

<style scoped lang="scss">
.app-aside {
	height: 100vh;
	.logo {
		display: flex;
		height: 60px;
		padding-left: 20px;
		align-items: center;
		.img {
			width: 28px;
			height: 28px;
		}
		.title {
			margin-left: 8px;
			width: 60px;
			height: 14px;
		}
	}
	.logo.only {
		padding-left: 16px;
	}
}
</style>
