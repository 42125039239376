<template>
	<div>
		<!-- <router-view v-slot="{ Component, route }">
			<keep-alive :include="routeQueueArr">
				<component :is="Component" :key="route.name" />
			</keep-alive>
		</router-view> -->
		<router-view />
	</div>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { VuexStateKey } from '@/store/vuexEnum'
import { useStore } from 'vuex'
import { LocalStorageKey } from '@/config/enum'
import { checkSameUser } from '@kzfe/learnable-lib'

let router = useRouter()
const store = useStore()
onMounted(() => {
	checkSameUser(LocalStorageKey.USER_INFO, () => {
		let info = router.resolve({
			name: 'login',
		})
		let url = location.origin + info.fullPath
		location.href = url
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve('')
			}, 500)
		})
	})
	const organizationId = localStorage.getItem('organizationId')
	if (organizationId) {
		store.commit(VuexStateKey.ORGANIZATION_ID, Number(organizationId))
	}
})
</script>
<style lang="scss">
body {
	width: 100vw;
	min-width: 1200px;
	overflow-x: scroll;
	overflow-y: hidden;
	@media screen and (min-width: 1200px) {
		max-width: 100vw;
		overflow-x: hidden;
	}
}
</style>
